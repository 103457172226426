<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PROFESSIONAL_REG_TYPES } from './store'
import { ROUTES as PROFESSIONAL_REG_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import ProfessionalRegisterTable from './ProfessionalRegisterTable'
import ProfessionalRegisterCommonFilters from './ProfessionalRegisterCommonFilters'
import ProfessionalRegisterEdit from './ProfessionalRegisterEdit.vue'

export default {
  name: 'ProfessionalRegisterList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ProfessionalRegisterTable,
    'common-filters': ProfessionalRegisterCommonFilters,
    'component-detail': ProfessionalRegisterEdit,
    'component-edit': ProfessionalRegisterEdit
  },
  data () {
    return {
      PROFESSIONAL_REG_ROUTES,
      title: this.$t('Professional registers'),
      rolePerm: ['miscellaneous_professionalregister_list'],
      actionEnablePermission: ['miscellaneous_professionalregister_enable'],
      actionDisablePermission: ['miscellaneous_professionalregister_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...PROFESSIONAL_REG_TYPES.GENERIC.miscellaneous.professionalregister.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.PROFESSIONAL_REG_ROUTES.PROFESSIONAL_REGISTER_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_professionalregister_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PROFESSIONAL_REG_TYPES.GENERIC.miscellaneous.professionalregister.LIST.MUTATIONS.setSelectedList,
      setSelectAll: PROFESSIONAL_REG_TYPES.GENERIC.miscellaneous.professionalregister.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PROFESSIONAL_REG_TYPES.GENERIC.miscellaneous.professionalregister.LIST.ACTIONS
    })
  }
}
</script>
